var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "match-height" },
    [
      _c("b-col", { attrs: { md: "6" } }, [_c("form-input-group-basic")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("form-input-group-merged")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("form-input-group-size")], 1),
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [_c("form-input-group-checkbox-radio")],
        1
      ),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [_c("form-input-group-button")],
        1
      ),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [_c("form-input-group-dropdown")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Merged" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeMerged) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "demo-vertical-spacing" },
        [
          _c(
            "b-input-group",
            { staticClass: "input-group-merge" },
            [
              _c(
                "b-input-group-prepend",
                { attrs: { "is-text": "" } },
                [_c("feather-icon", { attrs: { icon: "SearchIcon" } })],
                1
              ),
              _c("b-form-input", { attrs: { placeholder: "Search" } })
            ],
            1
          ),
          _c(
            "b-form-group",
            [
              _c("label", { attrs: { for: "basic-password1" } }, [
                _vm._v("Password")
              ]),
              _c(
                "b-input-group",
                { staticClass: "input-group-merge" },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "basic-password1",
                      type: _vm.passwordFieldType,
                      placeholder: "Your Password"
                    }
                  }),
                  _c(
                    "b-input-group-append",
                    { attrs: { "is-text": "" } },
                    [
                      _c("feather-icon", {
                        staticClass: "cursor-pointer",
                        attrs: { icon: _vm.passwordToggleIcon },
                        on: { click: _vm.togglePasswordVisibility }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-input-group",
            { staticClass: "input-group-merge", attrs: { prepend: "@" } },
            [_c("b-form-input", { attrs: { placeholder: "Username" } })],
            1
          ),
          _c(
            "b-input-group",
            {
              staticClass: "input-group-merge",
              attrs: { append: "@example.com" }
            },
            [
              _c("b-form-input", {
                attrs: { placeholder: "Recipient's username" }
              })
            ],
            1
          ),
          _c(
            "b-input-group",
            {
              staticClass: "input-group-merge",
              attrs: { prepend: "https://example.com/users/" }
            },
            [_c("b-form-input")],
            1
          ),
          _c(
            "b-input-group",
            {
              staticClass: "input-group-merge",
              attrs: { prepend: "$", append: ".00" }
            },
            [_c("b-form-input", { attrs: { placeholder: "100" } })],
            1
          ),
          _c(
            "b-input-group",
            {
              staticClass: "input-group-merge",
              attrs: { prepend: "With textarea" }
            },
            [_c("b-form-textarea")],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Checkbox and radio addons" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeCheckboxRadio) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "b-input-group-prepend",
                        { attrs: { "is-text": "" } },
                        [
                          _c("b-form-checkbox", {
                            attrs: { plain: "", name: "checkbox-input" }
                          })
                        ],
                        1
                      ),
                      _c("b-form-input", { attrs: { placeholder: "Message" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "b-input-group-prepend",
                        { attrs: { "is-text": "" } },
                        [
                          _c("b-form-radio", {
                            attrs: {
                              value: "radio2",
                              plain: "",
                              name: "radio-input1"
                            },
                            model: {
                              value: _vm.radio1,
                              callback: function($$v) {
                                _vm.radio1 = $$v
                              },
                              expression: "radio1"
                            }
                          })
                        ],
                        1
                      ),
                      _c("b-form-input", { attrs: { placeholder: "Message" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("b-col", { staticClass: " my-1", attrs: { cols: "12" } }, [
            _c("small", { staticClass: "font-weight-semibold" }, [
              _vm._v("Custom checkbox and radio")
            ])
          ]),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "b-input-group-prepend",
                        { attrs: { "is-text": "" } },
                        [
                          _c("b-form-checkbox", {
                            attrs: { name: "checkbox-input" }
                          })
                        ],
                        1
                      ),
                      _c("b-form-input", { attrs: { placeholder: "Message" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "b-input-group-prepend",
                        { attrs: { "is-text": "" } },
                        [
                          _c("b-form-radio", {
                            attrs: { value: "radio2", name: "radio-input1" },
                            model: {
                              value: _vm.radio1,
                              callback: function($$v) {
                                _vm.radio1 = $$v
                              },
                              expression: "radio1"
                            }
                          })
                        ],
                        1
                      ),
                      _c("b-form-input", { attrs: { placeholder: "Message" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Input Groups With Dropdown" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeDropdown) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "mb-1", attrs: { lg: "6" } },
            [
              _c(
                "b-input-group",
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c(
                        "b-dropdown",
                        {
                          attrs: { text: "Action", variant: "outline-primary" }
                        },
                        [
                          _c("b-dropdown-item", [_vm._v("Action ")]),
                          _c("b-dropdown-item", [_vm._v("Another Action")]),
                          _c("b-dropdown-item", [
                            _vm._v("Something else here")
                          ]),
                          _c("b-dropdown-divider"),
                          _c("b-dropdown-item", [_vm._v("Separated link")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    attrs: { placeholder: "Dropdown on left" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-input-group",
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c(
                        "b-dropdown",
                        {
                          attrs: { variant: "outline-primary" },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function() {
                                return [
                                  _c("feather-icon", {
                                    attrs: { icon: "Edit2Icon" }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("b-dropdown-item", [_vm._v("Action ")]),
                          _c("b-dropdown-item", [_vm._v("Another Action")]),
                          _c("b-dropdown-item", [
                            _vm._v("Something else here")
                          ]),
                          _c("b-dropdown-divider"),
                          _c("b-dropdown-item", [_vm._v("Separated link")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    attrs: { placeholder: "Dropdown on both side" }
                  }),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            text: "Action",
                            variant: "outline-primary",
                            right: ""
                          }
                        },
                        [
                          _c("b-dropdown-item", [_vm._v("Action ")]),
                          _c("b-dropdown-item", [_vm._v("Another Action")]),
                          _c("b-dropdown-item", [
                            _vm._v("Something else here")
                          ]),
                          _c("b-dropdown-divider"),
                          _c("b-dropdown-item", [_vm._v("Separated link")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }